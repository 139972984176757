import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Meta from '../components/meta';
import { Header, LiveShowSection } from '../components';
import { isBrowser } from '../helpers/isBrowser';

const LIVESCHEDULE = [
	{
		startTime: [2021, 10, 8, 9, 0, 0, 0],
		endTime: [2021, 10, 8, 10, 45, 0, 0],
	},
	{
		startTime: [2021, 10, 29, 8, 0, 0, 0],
		endTime: [2021, 10, 29, 10, 45, 0, 0],
	},
	{
		startTime: [2021, 11, 13, 8, 0, 0 , 0],
		endTime: [2021, 11, 13, 10, 45, 0, 0],
	},
];

const Liveshow = ({ data, location }) => {
	const [isLive, setLive] = useState(false);
	const { storyblokEntry }  = data;
	const liveshows = JSON.parse(storyblokEntry.content);

	useEffect(() => {
		if(isBrowser()) {
			let isLive = false;
			const interval = setInterval(() => {
				const now = new Date();
				isLive = LIVESCHEDULE.some(({ startTime, endTime }) => {
					const start = new Date(...startTime);
					const end = new Date(...endTime);
					return now > start && now < end;
				});
				setLive(isLive);
			}, 700);
			return () => clearInterval(interval);
		}
	}, []);

	return <Layout>
		<Meta />
		<Header color="white" location={location}/>
		{isLive ? <div className="min-h-screen bg-primary px-10p pt-87">
			<div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
				<iframe src="https://vimeo.com/event/1622232/embed" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position: 'absolute', top:0, left:0, width:'100%', height:'100%' , borderRadius: '30px'}}>
				</iframe>
			</div>
			<div className="py-16">
				<iframe src="https://vimeo.com/event/1622232/chat/" width="100%" height="400px" frameBorder="0" style={{borderRadius: '30px'}}></iframe>
			</div>
		</div>
			:
			<div className="min-h-screen pt-87 bg-secondary">
				{liveshows.body.map((liveshow, idx) => (
					<div key={`previous_stream_${idx}`}>
						{liveshow.component === 'liveshowSection' && <LiveShowSection {...liveshow}/>}
					</div>
				))}
			</div>
		}
	</Layout>;
};

export default Liveshow;

export const query = graphql`
	query mappuyQuery {
		storyblokEntry(slug: {eq: "tidligere-liveshows"}) {
			content
		}
	}
`;
